.portfolio__container {
  width: 50%;
  max-width: 1200px;
  margin: 0 auto;
}

.portfolio__card {
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: solid 0.1px rgba(0, 0, 0, 0.148);
  background-color: var(--container-color);
  margin-bottom: var(--mb-3);
  border-radius: 10px;
  /* Добавлен скругленный угол */
}

.text-portfolio {
  display: flex;
  align-items: start;
  flex-direction: column;
  padding: 10px 20px;
  gap: 8px;
}

.portfolio__img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media screen and (max-width: 992px) {
  .portfolio__container {
    width: initial;
  }

  .portfolio__card {
    height: auto;
    padding: 1.25em 1.5rem;
  }
}