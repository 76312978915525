.about__container {
  display: grid;
  /* ozgartirilgan qism */
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 4rem;
  margin-top: -35px;
}
.about__img {
  width: 350px;
  border-radius: 1.5rem;
  justify-self: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.about__info {
  display: grid;
  /* ozgartirilgan qism */
  grid-template-columns: repeat(3, 140px);
  gap: 0.5rem;
  margin-bottom: var(--mb-2);
}
.btn-cv {
  box-shadow: 0px 4px 8px -2px rgba(9, 30, 66, 0.25),
    0px 0px 0px 1px rgba(9, 30, 66, 0.08);
}
.about__box {
  background-color: var(--container-color);
  border: solid 1px rgb(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  text-align: center;
  padding: 1rem 1.25rem;
}
.about__icon {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-bottom: var(--mb-0-5);
}
.about__title {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}
.about__subtitle {
  font-size: var(--tiny-font-size);
}
.about__description {
  padding: 0 4rem 0 0;
  margin-bottom: var(--mb-2-5);
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .about__container {
    display: grid;
    /* ozgartirilgan */
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
  }
  .about__img {
    width: 250px;
  }
  .about__box {
    padding-top: 0.75rem 0.5rem;
  }
  .about__data {
    text-align: center;
  }
  .about__info {
    justify-content: center;
  }
  .about__description {
    padding: 0 5rem;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 576px) {
  .about__info {
    /* display: grid; */
    grid-template-columns: repeat(3, 1fr);
  }
  .about__description {
    padding: 0;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .about__info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
